import React from "react";

export default function About(){
    return (
        <section id="about">
                   <div className="flex justify-center items-center w-full h-full mt-4 mb-1 pt-2 pb-0">
  <div className="w-200 h-200">
    <img
      className="object-cover object-center rounded"
      alt="hero"
      src="./Myprofile.jpg"
      width="200"
      height="200"
      />
  </div>
</div>
          <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                Hi, I'm Mohamed Ali.
                {/* <br className="hidden lg:inline-block" />A Java Fullstack Software Developer */}
              </h1>
              <p className="mb-8 leading-relaxed">
              A versatile and solution focused Software Engineer with over 6 years’ in-depth, 
              diverse experience working in fast paced, complex and rapidly evolving organisations providing frontend and backend software development, across Start-ups, Finance and Education sectors. Able to function well under pressure, with excellent influencing, communication and interpersonal skills with an unrelenting commitment to supporting colleagues and ability to motivate and drive forward team performance; able to build and maintain long-term, credible and effective relationships. Highly motivated and `people focused` with advanced analytical and decision-making skills and the ability to work as part of a team or independently always providing exceptional service. A professional who is proactive and tenacious with a proven track record of managing full life-cycle projects from initial design to completion, designing and managing database schemas, with a notable ability to resolve complex issues using problem-solving skills, maintaining a composed demeanor under pressure, achieving results through effective teamworking and cross- functional collaboration whilst managing stakeholder expectations.
              </p>
              <div className="flex justify-center">
                <a
                  href="#contact"
                  className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
                  Work With Me
                </a>
                <a
                  href="#projects"
                  className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
                  See My Past Work
                </a>
              </div>
            </div>
          </div>
        </section>
      );
}