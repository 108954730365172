import React from "react";
import { TerminalIcon, AcademicCapIcon } from "@heroicons/react/solid";
import { blogs } from "../data";
import Preloader from "./Preloader/Preloader";
// import Timer from "./components/Countdown/Timer";
import Timer from "./Countdown/Timer";
import Optin from "./Optin/Optin";

export default function Blogs() {
  return (
    <section id="blogs">
      <div className="container px-5 py-10 mx-auto text-center">
        <AcademicCapIcon className="w-10 inline-block mb-4" />
        <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-12">
        Blog Section
        </h1>


        <h1>
          Page
          <br />
          Coming Soon
        </h1>
        {/* <Timer /> */}
        {/* <Optin /> */}
        {/* <Preloader /> */}


        {/* <div className="flex flex-wrap m-4">
          {testimonials.map((testimonial) => (
            <div className="p-4 md:w-1/2 w-full">
              <div className="h-full bg-gray-800 bg-opacity-40 p-8 rounded">
                <TerminalIcon className="block w-8 text-gray-500 mb-4" />
                <p className="leading-relaxed mb-6">{testimonial.quote}</p>
                <div className="inline-flex items-center">
                  <img
                    alt="testimonial"
                    src={testimonial.image}
                    className="w-12 rounded-full flex-shrink-0 object-cover object-center"
                  />
                  <span className="flex-grow flex flex-col pl-4">
                    <span className="title-font font-medium text-white">
                      {testimonial.name}
                    </span>
                    <span className="text-gray-500 text-sm uppercase">
                      {testimonial.company}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </section>
  );
}